.top-main_visual_slide {
  position: relative;
}

.top-main_visual_slide--img {
  width: 100%;
  height: 33.6749634vw;
  min-height: 460px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.top-main_visual_slide--img-slide01 {
  background-image: url("/images/top/top-main_visual_slide--img-slide01-pc.png");
}

.top-main_visual_slide--img-slide03 {
  background-image: url("/images/top/top-main_visual_slide--img-slide03-pc.png");
}

.top-main_visual_slide--img-slide04 {
  background-image: url("/images/top/top-main_visual_slide--img-slide04-pc.png");
}

.top-main_visual_slide--img-slide05 {
  background-image: url("/images/top/top-main_visual_slide--img-slide05-pc.png");
}

.top-main_visual_slide--img-slide06 {
  background-image: url("/images/top/top-main_visual_slide--img-slide06-pc.png");
}

.top-main_visual_slide--img-slide07 {
  background-image: url("/images/top/top-main_visual_slide--img-slide07-pc.png");
}

.top-main_visual_slide--img-slide08 {
  background-image: url("/images/top/top-main_visual_slide--img-slide08-pc.png");
}

.top-main_visual_slide--img-slide09 {
  background-image: url("/images/top/top-main_visual_slide--img-slide09-pc.png");
}

.top-main_visual_slide--img-slide10 {
  background-image: url("/images/top/top-main_visual_slide--img-slide10-pc.png");
}

.top-main_visual_slide--img-slide11 {
  background-image: url("/images/top/top-main_visual_slide--img-slide11-pc.png");
}

@media (max-width: 428px) {
  .top-main_visual_slide--img {
    height: auto;
    min-height: 530px;
  }
  .top-main_visual_slide--img-slide01 {
    background-image: url("/images/top/top-main_visual_slide--img-slide01-sp.png");
  }
  .top-main_visual_slide--img-slide02 {
    background-image: url("/images/top/top-main_visual_slide--img-slide02-sp.png");
  }
  .top-main_visual_slide--img-slide03 {
    background-image: url("/images/top/top-main_visual_slide--img-slide03-sp.png");
  }
  .top-main_visual_slide--img-slide04 {
    background-image: url("/images/top/top-main_visual_slide--img-slide04-sp.png");
  }
  .top-main_visual_slide--img-slide05 {
    background-image: url("/images/top/top-main_visual_slide--img-slide05-sp.png");
  }
  .top-main_visual_slide--img-slide06 {
    background-image: url("/images/top/top-main_visual_slide--img-slide06-sp.png");
  }
  .top-main_visual_slide--img-slide07 {
    background-image: url("/images/top/top-main_visual_slide--img-slide07-sp.png");
  }
  .top-main_visual_slide--img-slide08 {
    background-image: url("/images/top/top-main_visual_slide--img-slide08-sp.png");
  }
  .top-main_visual_slide--img-slide09 {
    background-image: url("/images/top/top-main_visual_slide--img-slide09-sp.png");
  }
  .top-main_visual_slide--img-slide10 {
    background-image: url("/images/top/top-main_visual_slide--img-slide10-sp.png");
  }
  .top-main_visual_slide--img-slide11 {
    background-image: url("/images/top/top-main_visual_slide--img-slide11-sp.png");
  }
}

.top-main_visual_slide .slick-dots {
  display: flex;
  position: absolute;
  bottom: 16px;
  left: calc(50% - 67px);
}

@media (max-width: 428px) {
  .top-main_visual_slide .slick-dots {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
  }
}

.top-main_visual_slide .slick-dots li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px 0 0;
  background: #ccc;
  cursor: pointer;
}

.top-main_visual_slide .slick-dots li:last-child {
  margin: 0;
}

.top-main_visual_slide .slick-dots li.slick-active {
  background: #82171c;
}

.top-main_visual_slide .slick-dots li button {
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
}

.top-lead {
  padding-top: 80px;
  padding-bottom: 90px;
}

@media (max-width: 428px) {
  .top-lead {
    padding-top: 56px;
    padding-bottom: 80px;
  }
}

.top-lead--ttl {
  text-align: center;
  color: #A77D42;
  font-size: 28px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.5;
}

@media (max-width: 428px) {
  .top-lead--ttl {
    font-size: 18px;
  }
}

.top-lead--subttl {
  font-weight: normal;
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.1em;
  color: #A77D42;
  text-align: center;
  line-height: 1.8;
  font-size: 18px;
  margin-top: 24px;
}

@media (max-width: 428px) {
  .top-lead--subttl {
    font-size: 14px;
    margin-top: 20px;
  }
}

.top-about {
  padding-top: 80px;
  padding-bottom: 90px;
  background-color: #f3f3f3;
}

@media (max-width: 428px) {
  .top-about {
    padding-top: 56px;
    padding-bottom: 80px;
  }
}

.top-about--ttl {
  letter-spacing: 0.1em;
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 32px;
}

@media (max-width: 428px) {
  .top-about--ttl {
    font-size: 18px;
  }
}

.top-about--wrap {
  margin: 0 auto;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 428px) {
  .top-about--wrap {
    max-width: calc(100% - 48px);
    display: block;
  }
}

.top-about--link {
  width: 288px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
}

.top-about--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .top-about--link {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
  }
  .top-about--link:first-child {
    margin-top: 0;
  }
}

@media (max-width: 428px) {
  .top-about--img {
    width: 48%;
  }
}

.top-about--lead {
  font-family: "Noto Serif JP", serif;
  text-align: center;
  margin-top: 16px;
  letter-spacing: 0.1em;
}

@media (max-width: 428px) {
  .top-about--lead {
    font-size: 14px;
    margin-top: 0;
    margin-left: 12px;
  }
}

.top-about--arrow {
  position: relative;
}

.top-about--arrow:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: -16px;
  top: calc(50% - 3.5px);
}

.top-pickup {
  padding-top: 80px;
  padding-bottom: 160px;
  background-color: #F3F3F3;
}

@media (max-width: 428px) {
  .top-pickup {
    padding-bottom: 96px;
  }
}

.top-pickup--ttl {
  text-align: center;
  color: #A77D42;
  font-size: 24px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin-bottom: 32px;
}

@media (max-width: 428px) {
  .top-pickup--ttl {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

.top-pickup_slide {
  margin: 0 auto;
  max-width: 988px;
  position: relative;
  left: -10px;
}

@media (max-width: 428px) {
  .top-pickup_slide {
    left: 0;
  }
}

.top-pickup_slide--item {
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 20px;
  width: 288px;
}

@media (max-width: 428px) {
  .top-pickup_slide--item {
    padding-left: 0;
    margin-left: 16px;
    margin-right: 16px;
    width: 280px;
  }
}

.top-pickup_slide--link {
  text-decoration: none;
  transition: 0.2s;
}

.top-pickup_slide--link:hover {
  opacity: 0.8;
}

.top-pickup_slide--img {
  display: block;
  margin: 0 auto;
  object-fit: cover;
  aspect-ratio: 288/360;
  width: 100%;
}

@media (max-width: 428px) {
  .top-pickup_slide--img {
    aspect-ratio: 280/360;
  }
}

.top-pickup_slide--lead {
  position: relative;
  margin-top: calc(-72px + 16px);
  left: -20px;
  padding-top: 22px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 30px;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.1em;
  line-height: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.8);
  color: #333333;
}

.top-pickup_slide--lead:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: 18px;
  top: calc(50% - 3.5px);
}

@media (max-width: 428px) {
  .top-pickup_slide--lead {
    left: 10px;
    max-width: calc(100% - 20px);
  }
}

.top-pickup_slide .slick-arrow {
  visibility: hidden;
  cursor: pointer;
}

.top-pickup_slide .slick-prev:after {
  content: '';
  visibility: visible;
  display: block;
  width: 18px;
  height: 18px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  transform: rotate(-135deg);
  position: absolute;
  left: -80px;
  top: calc(50% - 9px);
}

@media (max-width: 428px) {
  .top-pickup_slide .slick-prev:after {
    width: 10px;
    height: 10px;
    left: calc(50% - 160px);
    top: calc(50% - 5px);
    z-index: 1;
  }
}

.top-pickup_slide .slick-next:after {
  content: '';
  visibility: visible;
  display: block;
  width: 18px;
  height: 18px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  transform: rotate(45deg);
  position: absolute;
  right: -80px;
  top: calc(50% - 9px);
}

@media (max-width: 428px) {
  .top-pickup_slide .slick-next:after {
    width: 10px;
    height: 10px;
    right: calc(50% - 160px);
    top: calc(50% - 5px);
  }
}

.top-pickup_slide .slick-track {
  top: -12px;
}

.top-type {
  padding: 0 0 160px;
  background: #F3F3F3;
}

@media (max-width: 428px) {
  .top-type {
    padding-bottom: 96px;
  }
}

.top-type--list {
  margin: 0 auto 120px;
}

@media (max-width: 428px) {
  .top-type--list {
    margin: 0 auto 64px;
  }
}

.top-type--item {
  margin: 96px 0 0;
  display: flex;
  align-items: center;
  position: relative;
}

.top-type--item:first-child {
  margin: 0;
}

@media (max-width: 428px) {
  .top-type--item {
    display: block;
    margin: 48px 0 0;
  }
}

.top-type--img {
  width: 70vw;
  display: block;
}

@media (max-width: 428px) {
  .top-type--img {
    width: 350px;
  }
}

.top-type--img-even {
  order: 2;
  margin: 0 0 0 auto;
}

.top-type--box {
  width: 50vw;
  padding: 80px 0 96px 130px;
  background: rgba(255, 255, 255, 0.8);
  margin: 0 0 0 -20vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 428px) {
  .top-type--box {
    width: 350px;
    padding: 32px 25px 30px 30px;
    margin: -30px 0px 0 calc(100% - 350px);
    position: relative;
  }
}

.top-type--box-even {
  order: 1;
  padding: 80px 130px 96px;
  margin: 0 -20vw 0 0;
  z-index: 1;
}

@media (max-width: 428px) {
  .top-type--box-even {
    padding: 32px 25px 30px 30px;
    margin: -30px 0 0;
    position: relative;
    z-index: 0;
  }
}

.top-type--ttl {
  font-size: 48px;
  color: #A77D42;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin: 0 0 24px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
}

.top-type--ttl:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .top-type--ttl {
    font-size: 32px;
    margin: 0 0 16px;
  }
}

.top-type--arrow {
  position: relative;
}

.top-type--arrow:after {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  border-top: 2px solid #A17F4B;
  border-right: 2px solid #A17F4B;
  transform: rotate(45deg);
  position: absolute;
  right: -32px;
  top: calc(50% - 3.5px);
}

@media (max-width: 428px) {
  .top-type--arrow:after {
    border-top: 1px solid #A17F4B;
    border-right: 1px solid #A17F4B;
    right: -20px;
  }
}

.top-type--text {
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media (max-width: 428px) {
  .top-type--text {
    font-size: 14px;
  }
}

.top-type--links {
  display: flex;
  justify-content: flex-start;
  margin: 32px 0 0;
}

.top-type--links + .top-type--links {
  margin-top: 15px;
}

.top-type--link {
  color: #333;
  font-size: 13px;
  font-family: "Noto Serif JP", serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
  width: 130px;
  padding: 0 0 8px;
  margin: 0 0 0 32px;
  border-bottom: 1px solid #333;
  display: block;
  position: relative;
  text-decoration: none;
  transition: 0.2s;
}

.top-type--link:hover {
  opacity: 0.8;
}

.top-type--link:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: 0px;
  top: calc(50% - 7.5px);
}

.top-type--link:first-child {
  margin: 0;
}

.top-type--wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 880px;
  margin: 80px auto 0;
}

@media (max-width: 428px) {
  .top-type--wrap {
    width: calc(100% - 48px);
    margin: 64px auto 0;
  }
}

.top-type_box {
  width: 400px;
}

@media (max-width: 428px) {
  .top-type_box {
    width: 46%;
  }
}

.top-type_box--img {
  display: block;
  margin: 0 0 16px;
}

@media (max-width: 428px) {
  .top-type_box--img {
    margin: 0 0 12px;
  }
}

.top-type_box--ttl {
  font-size: 24px;
  color: #A77D42;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin: 0 0 16px;
}

@media (max-width: 428px) {
  .top-type_box--ttl {
    font-size: 20px;
    margin: 0 0 24px;
  }
}

.top-type_box--text {
  font-size: 15px;
  line-height: 1.5;
  margin: 0 0 24px;
}

@media (max-width: 428px) {
  .top-type_box--text {
    font-size: 14px;
    line-height: 1.7;
    margin: 0 0 16px;
  }
}

.top-type_box--link {
  font-family: "Noto Serif JP", serif;
  width: 120px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
  color: #333;
  border-bottom: 1px solid;
  padding: 0 0 8px;
  position: relative;
}

.top-type_box--link:hover {
  opacity: 0.8;
}

.top-type_box--link:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: 0;
  top: calc(50% - 3.5px);
}

@media (max-width: 428px) {
  .top-type_box--link {
    font-size: 13px;
  }
}

.top-brand {
  padding: 80px 0 160px;
}

@media (max-width: 428px) {
  .top-brand {
    padding: 40px 24px 96px;
  }
}

.top-brand--ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin: 0 0 24px;
}

@media (max-width: 428px) {
  .top-brand--ttl {
    font-size: 20px;
  }
}

.top-brand--wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .top-brand--wrap {
    width: 100%;
  }
}

.top-brand--link {
  color: #333;
  text-decoration: none;
  width: 310px;
  margin: 24px 0 0;
  transition: 0.3s;
}

.top-brand--link:hover {
  opacity: 0.8;
}

.top-brand--link:nth-child(-n+3) {
  margin: 0;
}

.top-brand--link:nth-child(3n-1) {
  margin: 24px auto 0 24px;
}

.top-brand--link:nth-child(3n-1):nth-child(2) {
  margin: 0 auto 0 24px;
}

@media (max-width: 428px) {
  .top-brand--link {
    width: calc(50% - 8px);
  }
  .top-brand--link:nth-child(-n+3) {
    margin: 16px 0 0;
  }
  .top-brand--link:nth-child(-n+2) {
    margin: 0;
  }
  .top-brand--link:nth-child(3n-1) {
    margin: 16px 0 0;
  }
  .top-brand--link:nth-child(3n-1):nth-child(2) {
    margin: 0;
  }
}

.top-brand--text {
  font-size: 15px;
}

.top-motto {
  background: url("/images/top/bg-top-motto.png") no-repeat center center;
  background-size: cover;
  padding-top: 130px;
}

@media (max-width: 428px) {
  .top-motto {
    padding-top: 60px;
    max-height: 342px;
  }
}

.top-motto--ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 40px;
  color: #fff;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 40px;
}

@media (max-width: 428px) {
  .top-motto--ttl {
    font-size: 24px;
    margin: 0 0 24px;
  }
}

.top-motto--text {
  font-size: 15px;
  color: #fff;
  line-height: 2;
  letter-spacing: 0.1em;
  text-align: center;
}

@media (max-width: 428px) {
  .top-motto--text {
    line-height: 1.7;
    margin-bottom: 40px;
  }
}

.top-motto--link {
  font-family: "Noto Serif JP", serif;
  color: #fff;
  letter-spacing: 0.1em;
  display: block;
  text-decoration: none;
  transition: 0.2s;
  border-bottom: 1px solid;
  padding: 0 0 8px;
  width: 200px;
  margin: 0 auto;
  position: relative;
}

.top-motto--link:hover {
  opacity: 0.8;
}

.top-motto--link:after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  right: 0;
  top: calc(50% - 2px);
}

@media (max-width: 428px) {
  .top-motto--link {
    font-size: 14px;
    width: 184px;
    margin: 0 auto 48px;
  }
}

.top-motto--wrap {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -72px;
}

@media (max-width: 428px) {
  .top-motto--wrap {
    display: block;
    bottom: 0;
  }
}

.top-motto--box {
  width: 280px;
  height: 216px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 8px 20px 24px;
  margin: 0 0 0 24px;
}

.top-motto--box:first-child {
  margin: 0;
}

@media (max-width: 428px) {
  .top-motto--box {
    width: calc(100% - 48px);
    height: 88px;
    padding: 8px;
    margin: 24px auto 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .top-motto--box:first-child {
    margin: 0 auto;
  }
}

@media (max-width: 428px) {
  .top-motto--img {
    width: 100px;
  }
}

.top-motto--lead {
  font-family: "Noto Serif JP", serif;
  color: #A77D42;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 428px) {
  .top-motto--lead {
    font-size: 14px;
    margin: 0 0 0 8px;
  }
}

.top-news {
  background: #F3F3F3;
  padding: 254px 0 120px;
}

@media (max-width: 428px) {
  .top-news {
    padding: 358px 0 96px;
  }
}

.top-news--inner {
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .top-news--inner {
    width: calc(100% - 48px);
  }
}

.top-news--ttl {
  font-size: 32px;
  font-family: "Noto Serif JP", serif;
  color: #333;
  font-weight: normal;
  letter-spacing: 0.1em;
  margin: 0 0 32px;
}

@media (max-width: 428px) {
  .top-news--ttl {
    font-size: 20px;
    margin: 0 0 24px;
    text-align: center;
  }
}

.top-news--box {
  margin: 0 0 80px;
}

@media (max-width: 428px) {
  .top-news--box {
    margin: 0 0 96px;
  }
}

.top-news--link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 15px;
  padding: 24px 0;
  border-bottom: 1px solid #d8d8d8;
  transition: 0.3s;
}

.top-news--link:first-child {
  border-top: 1px solid #d8d8d8;
}

.top-news--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .top-news--link {
    display: block;
    padding: 20px 0;
  }
}

.top-news--date {
  margin: 0 56px 0 0;
  display: block;
}

@media (max-width: 428px) {
  .top-news--date {
    margin: 0 0 8px;
  }
}

.top-news--text {
  line-height: 1.7;
}

.top-news--link_more {
  font-family: "Noto Serif JP", serif;
  width: 120px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
  color: #333;
  border-bottom: 1px solid;
  padding: 0 0 8px;
  margin: 24px 0 0 auto;
  position: relative;
}

.top-news--link_more:hover {
  opacity: 0.8;
}

.top-news--link_more:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: 0;
  top: calc(50% - 3.5px);
}

@media (max-width: 428px) {
  .top-news--link_more {
    font-size: 13px;
  }
}

.top-news_category {
  margin: 80px 0 0;
}

.top-news_category:first-of-type {
  margin: 0;
}

@media (max-width: 428px) {
  .top-news_category {
    margin: 40px 0 0;
  }
}

@media (max-width: 428px) {
  .top-news_category--content {
    background: #fff;
    padding: 40px 0;
  }
}

.top-news_category--box {
  width: 840px;
  background: #fff;
  padding: 64px;
}

@media (max-width: 428px) {
  .top-news_category--box {
    width: calc(100% - 20px);
    padding: 0 8px;
    background: none;
    margin: 0 auto;
  }
}

@media (max-width: 428px) {
  .top-news_category--lead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
}

.top-news_category--ttl {
  color: #A77D42;
  font-size: 18px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.75;
  margin-bottom: 32px;
}

@media (max-width: 428px) {
  .top-news_category--ttl {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.top-news_category--link_more {
  font-family: "Noto Serif JP", serif;
  font-size: 13px;
  width: 120px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
  color: #333;
  border-bottom: 1px solid;
  padding: 0 0 8px;
  position: relative;
}

.top-news_category--link_more:hover {
  opacity: 0.8;
}

.top-news_category--link_more:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: 0;
  top: calc(50% - 3.5px);
}

@media (max-width: 428px) {
  .top-news_category--link_more {
    width: 80px;
  }
}

.top-news_category--wrap {
  width: 670px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: -160px 0 0 auto;
}

@media (max-width: 428px) {
  .top-news_category--wrap {
    width: calc(100% - 20px);
    display: block;
    margin: 0 auto;
  }
}

.top-news_category--link {
  width: 202px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
}

.top-news_category--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .top-news_category--link {
    width: calc(100% - 60px);
    margin: 8px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top-news_category--link:first-child {
    margin: 0 auto;
  }
}

.top-news_category--img {
  display: block;
  margin: 0 0 10px;
  width: 202px;
  height: 202px;
  object-fit: cover;
}

@media (max-width: 428px) {
  .top-news_category--img {
    width: 100px;
    height: 100px;
    margin: 0;
  }
}

.top-news_category--text {
  font-size: 12px;
  line-height: 1.5;
  color: #333;
}

@media (max-width: 428px) {
  .top-news_category--text {
    margin: 0 0 0 12px;
  }
}

.top-icon {
  display: none;
  transition: 0.2s;
  pointer-events: none;
}

.top-icon:hover {
  opacity: 0.8;
}

.js-showed {
  width: 100px;
  height: 100px;
  display: block;
  animation-name: showIcon;
  animation-duration: 0.3s;
  position: fixed;
  z-index: 1;
  bottom: 32px;
  right: 32px;
  pointer-events: auto;
}

@media (max-width: 428px) {
  .js-showed {
    width: 80px;
    height: 80px;
    bottom: 92px;
    right: 16px;
  }
}

.top-instagram {
  border-bottom: 1px solid #DCDCDC;
}

.top-instagram--inner {
  padding-top: 153px;
  padding-bottom: 176px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 428px) {
  .top-instagram--inner {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.top-instagram--ttl {
  font-weight: normal;
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0.1em;
}

@media (max-width: 428px) {
  .top-instagram--ttl {
    font-size: 20px;
    text-align: center;
  }
}

.top-instagram--follow_link {
  font-family: "Noto Serif JP", serif;
  font-size: 13px;
  width: 120px;
  display: block;
  text-decoration: none;
  transition: 0.2s;
  color: #333;
  border-bottom: 1px solid;
  padding: 0 0 8px;
  position: relative;
  margin-top: 30px;
}

.top-instagram--follow_link:hover {
  opacity: 0.8;
}

.top-instagram--follow_link:after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E");
  width: 4px;
  height: 7px;
  position: absolute;
  right: 0;
  top: calc(50% - 3.5px);
}

@media (max-width: 428px) {
  .top-instagram--follow_link {
    width: 80px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.top-instagram--content {
  position: absolute;
  width: 720px;
  height: 240px;
  left: calc(50% - 224px);
  top: 76px;
}

@media (max-width: 428px) {
  .top-instagram--content {
    position: static;
    margin: 0 auto;
    margin-top: 50px;
    width: calc(100% - 16px);
    height: auto;
  }
}

@keyframes showIcon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
