@charset "UTF-8";
@import "../mixin";

.top-main_visual_slide {
  position: relative;
  &--img {
    width: 100%;
    height: 33.6749634vw;
    min-height: 460px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &-slide01 {
      background-image: url('/images/top/top-main_visual_slide--img-slide01-pc.png');
    }
    // &-slide02 {
    //   background-image: url('/images/top/top-main_visual_slide--img-slide02-pc.png');
    // }
    &-slide03 {
      background-image: url('/images/top/top-main_visual_slide--img-slide03-pc.png');
    }
    &-slide04 {
      background-image: url('/images/top/top-main_visual_slide--img-slide04-pc.png');
    }
    &-slide05 {
      background-image: url('/images/top/top-main_visual_slide--img-slide05-pc.png');
    }
    &-slide06 {
      background-image: url('/images/top/top-main_visual_slide--img-slide06-pc.png');
    }
    &-slide07 {
      background-image: url('/images/top/top-main_visual_slide--img-slide07-pc.png');
    }
    &-slide08 {
      background-image: url('/images/top/top-main_visual_slide--img-slide08-pc.png');
    }
    &-slide09 {
      background-image: url('/images/top/top-main_visual_slide--img-slide09-pc.png');
    }
    &-slide10 {
      background-image: url('/images/top/top-main_visual_slide--img-slide10-pc.png');
    }
    &-slide11 {
      background-image: url('/images/top/top-main_visual_slide--img-slide11-pc.png');
    }
    @include sp {
      height: auto;
      min-height: 530px;
      &-slide01 {
        background-image: url('/images/top/top-main_visual_slide--img-slide01-sp.png');
      }
      &-slide02 {
        background-image: url('/images/top/top-main_visual_slide--img-slide02-sp.png');
      }
      &-slide03 {
        background-image: url('/images/top/top-main_visual_slide--img-slide03-sp.png');
      }
      &-slide04 {
        background-image: url('/images/top/top-main_visual_slide--img-slide04-sp.png');
      }
      &-slide05 {
        background-image: url('/images/top/top-main_visual_slide--img-slide05-sp.png');
      }
      &-slide06 {
        background-image: url('/images/top/top-main_visual_slide--img-slide06-sp.png');
      }
      &-slide07 {
        background-image: url('/images/top/top-main_visual_slide--img-slide07-sp.png');
      }
      &-slide08 {
        background-image: url('/images/top/top-main_visual_slide--img-slide08-sp.png');
      }
      &-slide09 {
        background-image: url('/images/top/top-main_visual_slide--img-slide09-sp.png');
      }
      &-slide10 {
        background-image: url('/images/top/top-main_visual_slide--img-slide10-sp.png');
      }
      &-slide11 {
        background-image: url('/images/top/top-main_visual_slide--img-slide11-sp.png');
      }
    }
  }
  .slick-dots {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: calc(50% - 67px);
    @include sp {
      left: 0;
      right: 0;
      margin: 0 auto;
      width: fit-content;
    }
    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 6px 0 0;
      background: #ccc;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
      &.slick-active {
        background: $color-red_82171c;
      }
      button {
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.top-lead {
  padding-top: 80px;
  padding-bottom: 90px;
  @include sp {
    padding-top: 56px;
    padding-bottom: 80px;
  }
  &--ttl {
    text-align: center;
    color: #A77D42;
    font-size: 28px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    @include sp {
      font-size: 18px;
    }
  }
  &--subttl {
    font-weight: normal;
    font-family: $ff-noto_serif;
    letter-spacing: 0.1em;
    color: #A77D42;
    text-align: center;
    line-height: 1.8;
    font-size: 18px;
    margin-top: 24px;
    @include sp {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}
.top-about {
  padding-top: 80px;
  padding-bottom: 90px;
  background-color: #f3f3f3;
  @include sp {
    padding-top: 56px;
    padding-bottom: 80px;
  }
  &--ttl {
    letter-spacing: 0.1em;
    font-family: $ff-noto_serif;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 32px;
    @include sp {
      font-size: 18px;
    }
  }
  &--wrap {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include sp {
      max-width: calc(100% - 48px);
      display: block;
    }
  }
  &--link {
    width: 288px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      width: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 16px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &--img {
    @include sp {
      width: 48%;
    }
  }
  &--lead {
    font-family: $ff-noto_serif;
    text-align: center;
    margin-top: 16px;
    letter-spacing: 0.1em;
    @include sp {
      font-size: 14px;
      margin-top: 0;
      margin-left: 12px;
    }
  }
  &--arrow {
    position: relative;
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: -16px;
      top: calc(50% - 3.5px);
    }
  }
}
.top-pickup {
  padding-top: 80px;
  padding-bottom: 160px;
  background-color: #F3F3F3;
  @include sp {
    padding-bottom: 96px;
  }
  &--ttl {
    text-align: center;
    color: #A77D42;
    font-size: 24px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    margin-bottom: 32px;
    @include sp {
      font-size: 20px;
      margin-bottom: 24px;
    }
  }
}
.top-pickup_slide {
  margin: 0 auto;
  max-width: 988px;
  position: relative;
  left: -10px;
  @include sp {
    left: 0;
  }
  &--item {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 20px;
    width: 288px;
    @include sp {
      padding-left: 0;
      margin-left: 16px;
      margin-right: 16px;
      width: 280px;
    }
  }
  &--link {
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
  &--img {
    display: block;
    margin: 0 auto;
    object-fit: cover;
    aspect-ratio: 288/360;
    width: 100%;
    @include sp {
      aspect-ratio: 280/360;
    }
  }
  &--lead {
    position: relative;
    margin-top: calc(-72px + 16px);
    left: -20px;
    padding-top: 22px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 30px;
    font-weight: 500;
    font-family: $ff-noto_serif;
    letter-spacing: 0.1em;
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    background-color: rgba(255,255,255,0.8);
    color: #333333;
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: 18px;
      top: calc(50% - 3.5px);
    }
    @include sp {
      //left: calc(50% - 134px);
      //width: 268px;
      left: 10px;
      max-width: calc(100% - 20px);
    }
  }
  .slick-arrow {
    visibility: hidden;
    cursor: pointer;
  }
  .slick-prev {
    &:after {
      content: '';
      visibility: visible;
      display: block;
      width: 18px;
      height: 18px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      transform: rotate(-135deg);
      position: absolute;
      left: -80px;
      top: calc(50% - 9px);
      @include sp {
        width: 10px;
        height: 10px;
        left: calc(50% - 160px);
        top: calc(50% - 5px);
        z-index: 1;
      }
    }
  }
  .slick-next {
    &:after {
      content: '';
      visibility: visible;
      display: block;
      width: 18px;
      height: 18px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      transform: rotate(45deg);
      position: absolute;
      right: -80px;
      top: calc(50% - 9px);
      @include sp {
        width: 10px;
        height: 10px;
        right: calc(50% - 160px);
        top: calc(50% - 5px);
      }
    }
  }
  .slick-track {
    top: -12px;
  }
}
.top-type {
  padding: 0 0 160px;
  background: #F3F3F3;
  @include sp {
    padding-bottom: 96px;
  }
  &--list {
    margin: 0 auto 120px;
    @include sp {
      margin: 0 auto 64px;
    }
  }
  &--item {
    margin: 96px 0 0;
    display: flex;
    align-items: center;
    position: relative;
    &:first-child {
      margin: 0;
    }
    @include sp {
      display: block;
      margin: 48px 0 0;
    }
  }
  &--img {
    width: 70vw;
    display: block;
    @include sp {
      width: 350px;
    }
    &-even {
      order: 2;
      margin: 0 0 0 auto;
    }
  }
  &--box {
    width: 50vw;
    padding: 80px 0 96px 130px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0 0 0 -20vw;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    @include sp {
      width: 350px;
      padding: 32px 25px 30px 30px;
      margin: -30px 0px 0 calc(100% - 350px);
      position: relative;
    }
    &-even {
      order: 1;
      padding: 80px 130px 96px;
      margin: 0 -20vw 0 0;
      z-index: 1;
      @include sp {
        padding: 32px 25px 30px 30px;
        margin: -30px 0 0;
        position: relative;
        z-index: 0;
      }
    }
  }
  &--ttl {
    font-size: 48px;
    color: #A77D42;
    font-family: $ff-noto_serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    margin: 0 0 24px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      font-size: 32px;
      margin: 0 0 16px;
    }
  }
  &--arrow {
    position: relative;
    &:after {
      content: '';
      width: 8px;
      height: 8px;
      display: block;
      border-top: 2px solid #A17F4B;
      border-right: 2px solid #A17F4B;
      transform: rotate(45deg);
      position: absolute;
      right: -32px;
      top: calc(50% - 3.5px);
      @include sp {
        border-top: 1px solid #A17F4B;
        border-right: 1px solid #A17F4B;
        right: -20px;
      }
    }
  }
  &--text {
    font-size: 15px;
    line-height: 2;
    letter-spacing: 0.1em;
    @include sp {
      font-size: 14px;
    }
  }
  &--links {
    display: flex;
    justify-content: flex-start;
    margin: 32px 0 0;
    & + & {
      margin-top: 15px;
    }
  }
  &--link {
    color: #333;
    font-size: 13px;
    font-family: $ff-noto_serif;
    line-height: 1.5;
    letter-spacing: 0.1em;
    width: 130px;
    padding: 0 0 8px;
    margin: 0 0 0 32px;
    border-bottom: 1px solid #333;
    display: block;
    position: relative;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: 0px;
      top: calc(50% - 7.5px);
    }
    &:first-child {
      margin: 0;
    }
  }
  &--wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 880px;
    margin: 80px auto 0;
    @include sp {
      width: calc(100% - 48px);
      margin: 64px auto 0;
    }
  }
}

.top-type_box {
  width: 400px;
  @include sp {
    width: 46%;
  }
  &--img {
    display: block;
    margin: 0 0 16px;
    @include sp {
      margin: 0 0 12px;
    }
  }
  &--ttl {
    font-size: 24px;
    color: #A77D42;
    font-family: $ff-noto_serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    margin: 0 0 16px;
    @include sp {
      font-size: 20px;
      margin: 0 0 24px;
    }
  }
  &--text {
    font-size: 15px;
    line-height: 1.5;
    margin: 0 0 24px;
    @include sp {
      font-size: 14px;
      line-height: 1.7;
      margin: 0 0 16px;
    }
  }
  &--link {
    font-family: $ff-noto_serif;
    width: 120px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    color: #333;
    border-bottom: 1px solid;
    padding: 0 0 8px;
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: 0;
      top: calc(50% - 3.5px);
    }
    @include sp {
      font-size: 13px;
    }
  }
}

.top-brand {
  padding: 80px 0 160px;
  @include sp {
    padding: 40px 24px 96px;
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin: 0 0 24px;
    @include sp {
      font-size: 20px;
    }
  }
  &--wrap {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    width: 1000px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }
  &--link {
    color: #333;
    text-decoration: none;
    width: 310px;
    margin: 24px 0 0;
    transition: 0.3s;
    &:hover {
      opacity: 0.8;
    }
    &:nth-child(-n+3) {
      margin: 0;
    }
    &:nth-child(3n-1) {
      margin: 24px auto 0 24px;
      &:nth-child(2) {
        margin: 0 auto 0 24px;
      }
    }
    @include sp {
      width: calc(50% - 8px);
      &:nth-child(-n+3) {
        margin: 16px 0 0;
      }
      &:nth-child(-n+2) {
        margin: 0;
      }
      &:nth-child(3n-1) {
        margin: 16px 0 0;
        &:nth-child(2) {
          margin: 0;
        }
      }
    }
  }
  &--text {
    font-size: 15px;
  }
}

.top-motto {
  background: url('/images/top/bg-top-motto.png') no-repeat center center;
  background-size: cover;
  padding-top: 130px;
  @include sp {
    padding-top: 60px;
    max-height: 342px;
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-size: 40px;
    color: #fff;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 40px;
    @include sp {
      font-size: 24px;
      margin: 0 0 24px;
    }
  }
  &--text {
    font-size: 15px;
    color: #fff;
    line-height: 2;
    letter-spacing: 0.1em;
    text-align: center;
    @include sp {
      line-height: 1.7;
      margin-bottom: 40px;
    }
  }
  &--link {
    font-family: $ff-noto_serif;
    color: #fff;
    letter-spacing: 0.1em;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    border-bottom: 1px solid;
    padding: 0 0 8px;
    width: 200px;
    margin: 0 auto;
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      top: calc(50% - 2px);
    }
    @include sp {
      font-size: 14px;
      width: 184px;
      margin: 0 auto 48px;
    }
  }
  &--wrap {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -72px;
    @include sp {
      display: block;
      bottom: 0;
    }
  }
  &--box {
    width: 280px;
    height: 216px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    padding: 8px 20px 24px;
    margin: 0 0 0 24px;
    &:first-child {
      margin: 0;
    }
    @include sp {
      width: calc(100% - 48px);
      height: 88px;
      padding: 8px;
      margin: 24px auto 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:first-child {
        margin: 0 auto;
      }
    }
  }
  &--img {
    @include sp {
     width: 100px;
    }
  }
  &--lead {
    font-family: $ff-noto_serif;
    color: #A77D42;
    font-size: 18px;
    text-align: center;
    @include sp {
      font-size: 14px;
      margin: 0 0 0 8px;
    }
  }
}

.top-news {
  background: #F3F3F3;
  padding: 254px 0 120px;
  @include sp {
    padding: 358px 0 96px;
  }
  &--inner {
    width: 1000px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 48px);
    }
  }
  &--ttl {
    font-size: 32px;
    font-family: $ff-noto_serif;
    color: #333;
    font-weight: normal;
    letter-spacing: 0.1em;
    margin: 0 0 32px;
    @include sp {
      font-size: 20px;
      margin: 0 0 24px;
      text-align: center;
    }
  }
  &--box {
    margin: 0 0 80px;
    @include sp {
      margin: 0 0 96px;
    }
  }
  &--link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 15px;
    padding: 24px 0;
    border-bottom: 1px solid $color-gray_d8d8d8;
    transition: 0.3s;
    &:first-child {
      border-top: 1px solid $color-gray_d8d8d8;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      display: block;
      padding: 20px 0;
    }
  }
  &--date {
    margin: 0 56px 0 0;
    display: block;
    @include sp {
      margin: 0 0 8px;
    }
  }
  &--text {
    line-height: 1.7;
  }
  &--link_more {
    font-family: $ff-noto_serif;
    width: 120px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    color: #333;
    border-bottom: 1px solid;
    padding: 0 0 8px;
    margin: 24px 0 0 auto;
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: 0;
      top: calc(50% - 3.5px);
    }
    @include sp {
      font-size: 13px;
    }
  }
}

.top-news_category {
  margin: 80px 0 0;
  &:first-of-type {
    margin: 0;
  }
  @include sp {
    margin: 40px 0 0;
  }
  &--content {
    @include sp {
      background: #fff;
      padding: 40px 0;
    }
  }
  &--box {
    width: 840px;
    background: #fff;
    padding: 64px;
    @include sp {
      width: calc(100% - 20px);
      padding: 0 8px;
      background: none;
      margin: 0 auto;
    }
  }
  &--lead {
    @include sp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
  }
  &--ttl {
    color: #A77D42;
    font-size: 18px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1.75;
    margin-bottom: 32px;
    @include sp {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  &--link_more {
    font-family: $ff-noto_serif;
    font-size: 13px;
    width: 120px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    color: #333;
    border-bottom: 1px solid;
    padding: 0 0 8px;
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: 0;
      top: calc(50% - 3.5px);
    }
    @include sp {
      width: 80px;
    }
  }
  &--wrap {
    width: 670px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: -160px 0 0 auto;
    @include sp {
      width: calc(100% - 20px);
      display: block;
      margin: 0 auto;
    }
  }
  &--link {
    width: 202px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      width: calc(100% - 60px);
      margin: 8px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        margin: 0 auto;
      }
    }
  }
  &--img {
    display: block;
    margin: 0 0 10px;
    width: 202px;
    height: 202px;
    object-fit: cover;
    @include sp {
      width: 100px;
      height: 100px;
      margin: 0;
    }
  }
  &--text {
    font-size: 12px;
    line-height: 1.5;
    color: #333;
    @include sp {
      margin: 0 0 0 12px;
    }
  }
}

.top-icon {
  display: none;
  transition: 0.2s;
  pointer-events: none;
  &:hover {
    opacity: 0.8;
  }
}
.js-showed {
  width: 100px;
  height: 100px;
  display: block;
  animation-name: showIcon;
  animation-duration: 0.3s;
  position: fixed;
  z-index: 1;
  bottom: 32px;
  right: 32px;
  pointer-events: auto;
  @include sp {
    width: 80px;
    height: 80px;
    bottom: 92px;
    right: 16px;
  }
}
.top-instagram {
  border-bottom: 1px solid #DCDCDC;
  &--inner {
    padding-top: 153px;
    padding-bottom: 176px;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    @include sp {
      padding-top: 50px;
      padding-bottom: 60px;
    }
  }
  &--ttl {
    font-weight: normal;
    font-family: $ff-noto_serif;
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 0.1em;
    @include sp {
      font-size: 20px;
      text-align: center;
    }
  }
  &--follow_link {
    font-family: $ff-noto_serif;
    font-size: 13px;
    width: 120px;
    display: block;
    text-decoration: none;
    transition: 0.2s;
    color: #333;
    border-bottom: 1px solid;
    padding: 0 0 8px;
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.061%22%20height%3D%226.707%22%20viewBox%3D%220%200%204.061%206.707%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1026%22%20data-name%3D%22%E3%83%91%E3%82%B9%201026%22%20d%3D%22M.354.354l3%2C3-3%2C3%22%20transform%3D%22translate(0)%22%20fill%3D%22none%22%20stroke%3D%22%23333%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E');
      width: 4px;
      height: 7px;
      position: absolute;
      right: 0;
      top: calc(50% - 3.5px);
    }
    @include sp {
      width: 80px;
      margin: 0 auto;
      margin-top: 50px;
    }
    margin-top: 30px;
  }
  &--content {
    position: absolute;
    width: 720px;
    height: 240px;
    left: calc(50% - 224px);
    top: 76px;
    @include sp {
      position: static;
      margin: 0 auto;
      margin-top: 50px;
      width: calc(100% - 16px);
      height: auto;
    }
  }
}

@keyframes showIcon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}